var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [(_vm.big_btn)?_c('v-btn',_vm._g(_vm._b({attrs:{"loading":_vm.loading}},'v-btn',attrs,false),Object.assign({}, dialog)),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.activator_icon)+" ")]),_vm._v(" "+_vm._s(_vm.activator_name)+" ")],1):_vm._e(),(!_vm.big_btn)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [(!_vm.big_btn)?_c('v-icon',_vm._g(_vm._b({staticClass:"mx-4",attrs:{"small":""}},'v-icon',attrs,false),Object.assign({}, dialog,tooltip)),[_vm._v(" "+_vm._s(_vm.activator_icon)+" ")]):_vm._e()]}}],null,true)},[(!_vm.big_btn)?_c('span',[_vm._v(_vm._s(_vm.tooltip_text))]):_vm._e()]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"vform",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handle_submit)}}},[_c('v-card',{staticClass:"light-blue lighten-3"},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.form_title))])]),_c('v-card-text',[_c('v-row',[_vm._l((_vm.non_field_errors),function(err){return _c('span',{key:err,staticClass:"red--text"},[_vm._v(_vm._s(err))])}),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"mode":"passive","vid":"name","name":"Period Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Period Name*","hint":"Something unique like 2022","required":"","autocomplete":"nope","maxlength":"256","counter":""},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}}),_vm._l((errors),function(err){return _c('span',{key:err,staticClass:"red--text"},[_vm._v(_vm._s(err))])})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"mode":"passive","vid":"from_date","name":"From Date","rules":"required|compare_dates:@to_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"From Date","hint":"Date from which period is supposed to start from","prepend-icon":"mdi-calendar","readonly":"","required":""},model:{value:(_vm.item.from_date),callback:function ($$v) {_vm.$set(_vm.item, "from_date", $$v)},expression:"item.from_date"}},'v-text-field',attrs,false),on)),_vm._l((errors),function(err){return _c('span',{key:err,staticClass:"red--text"},[_vm._v(_vm._s(err))])})]}}],null,true)})]}}],null,true),model:{value:(_vm.from_date_menu),callback:function ($$v) {_vm.from_date_menu=$$v},expression:"from_date_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.from_date_menu = false}},model:{value:(_vm.item.from_date),callback:function ($$v) {_vm.$set(_vm.item, "from_date", $$v)},expression:"item.from_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"mode":"passive","vid":"to_date","name":"To Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"To Date","hint":"Date on which period is supposed to end","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.item.to_date),callback:function ($$v) {_vm.$set(_vm.item, "to_date", $$v)},expression:"item.to_date"}},'v-text-field',attrs,false),on)),_vm._l((errors),function(err){return _c('span',{key:err,staticClass:"red--text"},[_vm._v(_vm._s(err))])})]}}],null,true)})]}}],null,true),model:{value:(_vm.to_date_menu),callback:function ($$v) {_vm.to_date_menu=$$v},expression:"to_date_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.to_date_menu = false}},model:{value:(_vm.item.to_date),callback:function ($$v) {_vm.$set(_vm.item, "to_date", $$v)},expression:"item.to_date"}})],1)],1)],2),_c('small',[_vm._v("*indicates required field")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","type":"submit","loading":_vm.submit_loading}},[_vm._v(" Save ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }