<template>
  <v-container v-if="has_view_permission">
    <v-card class="my-1 pa-3 blue accent-1">
      <v-card-title>
        <add_edit_form v-if="add_button" activator_name="Add New Yearly Period"
                       form_title="Add New Yearly Period" :url="add_url"
                       activator_icon="mdi-plus-thick" action="add" @form_status="get_lists"
                       big_btn="true"
                       snackbar_text="Yearly period#{value} has been created successfully"
                       :loading="reload_btn"
        ></add_edit_form>
        <v-btn :loading="reload_btn" @click="get_lists" class="ma-1">
          <v-icon>
            mdi-reload
          </v-icon>
          Reload Data Table
        </v-btn>
      </v-card-title>
    </v-card>
    <v-card class="yellow lighten-2">
      <v-data-table
          :headers="column_headers"
          :items="table_items"
          sort-by="id"
          class="elevation-5 yellow lighten-2"
          :loading="table_loading"
          :search="search"
          loading-text="Loading... Please wait"
          item-key="id"
          show-expand
          single-expand
          name="period_table"
      >
        <template v-slot:top>
          <v-card-title
              flat
          >
            Periods
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>
        </template>
        <template v-slot:item.actions="{ item }">
          <!--          {{ item }}-->
          <add_edit_form v-if="edit_button(item)" activator_name="Edit"
                         form_title="Edit Yearly Period" :passed_item="item" :url="edit_url"
                         activator_icon="mdi-pencil" action="edit" @form_status="get_lists"
                         tooltip_text="Edit"
                         :snackbar_text="`Yearly period#${item.name} has been updated successfully`">

          </add_edit_form>
          <delete_dialog v-if="delete_button(item)" @form_status="get_lists" :passed_item="item"
                         :dialogue_text="`Are you sure you want to Delete period# ${item.name}?`"
                         :url="`${delete_url.replace('{id}', item.id)}`"
                         activator_name="Delete" activator_icon="mdi-delete"
                         tooltip_text="Delete"
                         snackbar_text="Yearly Period#{value} deleted"
                         action="delete"
          ></delete_dialog>
          <delete_dialog v-if="authorize_button(item)" @form_status="get_lists" :passed_item="item"
                         :dialogue_text="`Are you sure you want to Authorize period# ${item.name}?`"
                         :url="`${authorize_url.replace('{id}', item.id)}`"
                         activator_name="Authorize" activator_icon="mdi-auto-fix"
                         tooltip_text="Authorize"
                         snackbar_text="Yearly Period#{value} has been authorized successfully"
                         action="authorize"
          ></delete_dialog>
          <delete_dialog v-if="close_button(item)" @form_status="get_lists" :passed_item="item"
                         :dialogue_text="`Are you sure you want to Close period# ${item.name}?`"
                         :url="`${close_url.replace('{id}', item.id)}`"
                         activator_name="Close" activator_icon="mdi-clover"
                         tooltip_text="Close Period"
                         snackbar_text="Yearly Period#{value} has been closed successfully"
                         action="close"
          ></delete_dialog>
        </template>
        <template v-slot:expanded-item="{ item, headers }">
          <td :colspan="headers.length" v-if="item.authorized">
            <HalfYearlyPeriodList :parent_item="item"/>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import add_edit_form from "@/components/periods/yearly_period_sub_component/add_edit_form";
import delete_dialog from "@/components/periods/yearly_period_sub_component/delete_dialog";
import Periods_urls from "@/constants/period_urls";
import HalfYearlyPeriodList from "@/components/periods/half_yearly_period_sub_component/HalfYearlyPeriodList";
import PeriodFunctions from "@/constants/PeriodFunctons";


export default {
  name: "UserListTable",
  components: {add_edit_form, delete_dialog, HalfYearlyPeriodList},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    has_view_permission() {
      return this.$store.getters.permissionCheckers('periods.view_yearlyperiods')
    },
    details_button() {
      return this.$store.getters.permissionCheckers('periods.yearly_details')
    },
    add_button() {
      return this.$store.getters.permissionCheckers('periods.add_yearlyperiods')
    },
  },
  data: () => ({
    reload_btn: false,
    table_loading: false,
    column_headers: [
      {
        text: 'Period Name',
        align: 'center',
        filterable: true,
        value: 'name',
      },
      {
        text: 'From Date',
        align: 'center',
        filterable: true,
        value: 'from_date',
      },
      {
        text: 'To Date',
        align: 'center',
        filterable: true,
        value: 'to_date',
      },
      {
        text: 'Authorized',
        align: 'center',
        filterable: true,
        value: 'authorized',
      },
      {
        text: 'Closed',
        align: 'center',
        filterable: true,
        value: 'closed',
      },
      {
        text: 'Actions',
        align: 'center',
        filterable: false,
        value: 'actions',
      },
      {
        text: '',
        align: 'center',
        filterable: false,
        value: 'data-table-expand',
      },
    ],
    table_items: [],
    search: '',
    show_snackbar: false,
    snackbar_text: null,
    add_url: Periods_urls.list,
    edit_url: Periods_urls.edit_a_yearly,
    delete_url: Periods_urls.delete_a_yearly,
    authorize_url: Periods_urls.authorize_a_yearly,
    close_url: Periods_urls.close_a_yearly,
  }),
  methods: {
    async get_lists() {
      this.axios_conf
      this.reload_btn = true
      this.table_loading = true
      let self = this
      this.table_items = []
      await axios.get(Periods_urls.list).then((resp) => {
        // console.log(resp)
        this.table_items = resp.data.periods
        this.$store.commit('setJWT', resp.data.key)
        self.reload_btn = false
        self.table_loading = false
        PeriodFunctions.monthly.last()
      })
    },
    edit_button(item) {
      return this.$store.getters.permissionCheckers('periods.change_yearlyperiods') && !item.authorized
    },
    delete_button(item) {
      return this.$store.getters.permissionCheckers('periods.delete_yearlyperiods') && !item.authorized
    },
    authorize_button(item) {
      return this.$store.getters.permissionCheckers('periods.authorize_yearly') && !item.authorized && this.$store.state.user !== item.inputter.username
    },
    close_button(item) {
      return this.$store.getters.permissionCheckers('periods.close_yearly') && item.authorized && !item.closed
    },
  },
  mounted() {
    this.get_lists();
  }
}
</script>

<style>
div[name="period_table"] > div > table > tbody > tr:hover {
  background: #82B1FF !important;
}
</style>