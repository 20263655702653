<template>
  <v-container v-if="has_view_permission" class="px-0">
    <v-data-table
        :headers="column_headers"
        :items="table_items"
        sort-by="id"
        class="elevation-5 yellow lighten-2"
        :loading="table_loading"
        loading-text="Loading... Please wait"
        item-key="id"
        show-expand
        single-expand
        name="quarterly_table"
        hide-default-footer
    >
      <template v-slot:top>
        <v-card-title
            flat
        >
          Quarterly Periods
          <v-spacer></v-spacer>
          <add_edit_form v-if="add_button" activator_name="Add New Period"
                         :form_title="`Add New Quarterly Period (Half Yearly#${parent_item.name})`"
                         :url="add_url.replace('{parent}', parent_item.id)"
                         :parent_item="parent_item"
                         activator_icon="mdi-plus-thick" action="add" @form_status="get_lists"
                         big_btn="true"
                         snackbar_text="Quarterly period#{value} has been created successfully"
                         :loading="reload_btn"
          ></add_edit_form>
          <v-btn :loading="reload_btn" @click="get_lists" class="ma-1">
            <v-icon>
              mdi-reload
            </v-icon>
            Reload Data Table
          </v-btn>
        </v-card-title>
      </template>
      <template v-slot:item.actions="{ item }">
        <add_edit_form v-if="edit_button(item)" activator_name="Edit"
                       form_title="Edit Quarterly Period" :passed_item="item"
                       :url="edit_url.replace('{parent}', parent_item.id).replace('{id}', item.id)"
                       activator_icon="mdi-pencil" action="edit" @form_status="get_lists"
                       tooltip_text="Edit"
                       :snackbar_text="`Quarterly period#${item.name} has been updated successfully`">

        </add_edit_form>
        <delete_dialog v-if="delete_button(item)" @form_status="get_lists" :passed_item="item"
                       :dialogue_text="`Are you sure you want to Delete period# ${item.name}?`"
                       :url="`${delete_url.replace('{parent}', parent_item.id).replace('{id}', item.id)}`"
                       activator_name="Delete" activator_icon="mdi-delete"
                       tooltip_text="Delete"
                       snackbar_text="Quarterly Period#{value} deleted"
                       action="delete"
        ></delete_dialog>
        <delete_dialog v-if="authorize_button(item)" @form_status="get_lists" :passed_item="item"
                       :dialogue_text="`Are you sure you want to Authorize period# ${item.name}?`"
                       :url="`${authorize_url.replace('{parent}', parent_item.id).replace('{id}', item.id)}`"
                       activator_name="Authorize" activator_icon="mdi-auto-fix"
                       tooltip_text="Authorize"
                       snackbar_text="Quarterly Period#{value} has been authorized successfully"
                       action="authorize"
        ></delete_dialog>
        <delete_dialog v-if="close_button(item)" @form_status="get_lists" :passed_item="item"
                       :dialogue_text="`Are you sure you want to Close period# ${item.name}?`"
                       :url="`${close_url.replace('{parent}', parent_item.id).replace('{id}', item.id)}`"
                       activator_name="Close" activator_icon="mdi-clover"
                       tooltip_text="Close Period"
                       snackbar_text="Quarterly Period#{value} has been closed successfully"
                       action="close"
        ></delete_dialog>
      </template>
      <template v-slot:expanded-item="{ item, headers }">
        <td :colspan="headers.length" v-if="item.authorized">
          <monthly-period-list :parent_item="item"/>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>


import axios from "axios";
import Periods_urls from "@/constants/period_urls";
import add_edit_form from "@/components/periods/half_yearly_period_sub_component/add_edit_form";
import delete_dialog from "@/components/periods/half_yearly_period_sub_component/delete_dialog";
import MonthlyPeriodList from "@/components/periods/monthly_sub_component/MonthlyPeriodList";
import PeriodFunctions from "@/constants/PeriodFunctons";

export default {
  name: "QuarterlyPeriodList",
  components: {add_edit_form, delete_dialog, MonthlyPeriodList},
  props: ['parent_item'],
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    has_view_permission() {
      return this.$store.getters.permissionCheckers('periods.view_quarterlyperiods')
    },
    add_button() {
      return this.$store.getters.permissionCheckers('periods.add_quarterlyperiods') && this.table_items.length < 2 && !this.parent_item.closed
    },
  },
  data: () => ({
    table_items: [],
    skeleton_loader_attrs: {
      boilerplate: true,
    },
    show_snackbar: false,
    snackbar_text: '',
    add_url: Periods_urls.quarterly.list,
    edit_url: Periods_urls.quarterly.edit,
    delete_url: Periods_urls.quarterly.delete,
    authorize_url: Periods_urls.quarterly.authorize,
    close_url: Periods_urls.quarterly.close,
    table_loading: false,
    reload_btn: false,
    column_headers: [
      {
        text: 'Period Name',
        align: 'center',
        filterable: true,
        value: 'name',
      },
      {
        text: 'From Date',
        align: 'center',
        filterable: true,
        value: 'from_date',
      },
      {
        text: 'To Date',
        align: 'center',
        filterable: true,
        value: 'to_date',
      },
      {
        text: 'Authorized',
        align: 'center',
        filterable: true,
        value: 'authorized',
      },
      {
        text: 'Closed',
        align: 'center',
        filterable: true,
        value: 'closed',
      },
      {
        text: 'Actions',
        align: 'center',
        filterable: false,
        value: 'actions',
      },
      {
        text: '',
        align: 'center',
        filterable: false,
        value: 'data-table-expand',
      },
    ],
  }),
  methods: {
    get_lists() {
      this.axios_conf
      let self = this
      let config = {
        headers: {
          multi: 'true'
        }
      }
      this.reload_btn = true
      this.table_loading = true
      this.table_items = []
      axios.get(Periods_urls.quarterly.list.replace('{parent}', this.parent_item.id), config).then((resp) => {
        this.table_items = resp.data.periods
        this.$store.commit('setJWT', resp.data.key)
        self.table_loading = false
        this.reload_btn = false
        PeriodFunctions.monthly.last()
      })
    },
    edit_button(item) {
      return this.$store.getters.permissionCheckers('periods.change_quarterlyperiods') && !item.authorized
    },
    delete_button(item) {
      return this.$store.getters.permissionCheckers('periods.delete_quarterlyperiods') && !item.authorized
    },
    authorize_button(item) {
      return this.$store.getters.permissionCheckers('periods.authorize_quarterly') && !item.authorized && this.$store.state.user !== item.inputter.username
    },
    close_button(item) {
      return this.$store.getters.permissionCheckers('periods.close_quarterly') && item.authorized && !item.closed
    },
  },
  mounted() {
    this.get_lists()
  },
  watch: {}
}
</script>

<style>
div[name="quarterly_table"] > div > table > tbody > tr:hover {
  background: #82B1FF !important;
}
</style>