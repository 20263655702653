<template>
  <v-dialog
      max-width="600px"
      v-model="dialog"
  >

    <template v-slot:activator="{ on:dialog, attrs }">
      <v-btn v-if="big_btn" v-bind="attrs" v-on="{...dialog}" :loading="loading">
        <v-icon>
          {{ activator_icon }}
        </v-icon>
        {{ activator_name }}
      </v-btn>
      <v-tooltip bottom v-if="!big_btn">
        <template v-slot:activator="{on:tooltip}">
          <v-icon v-if="!big_btn" v-bind="attrs" small
                  v-on="{...dialog,...tooltip}" class="mx-4">
            {{ activator_icon }}
          </v-icon>
        </template>
        <span v-if="!big_btn">{{ tooltip_text }}</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="observer" v-slot="{ handleSubmit}">
      <v-form ref="vform" @submit.prevent="handleSubmit(handle_submit)">
        <v-card class="light-blue lighten-3">
          <v-card-title>
            <span class="text-h5">{{ form_title }}</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <ValidationProvider mode="passive" vid="non_field" name="Non Field" rules="" v-slot="{ errors }">
                <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
              </ValidationProvider>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="name" name="Period Name" rules="required" v-slot="{ errors }">
                  <v-text-field
                      label="Period Name*"
                      hint="Something unique like 2022"
                      required
                      autocomplete="nope"
                      v-model="item.name"
                      maxlength="256"
                      counter
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >

                <v-menu
                    v-model="from_date_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider mode="passive" vid="from_date" name="From Date"
                                        rules="required|compare_dates:@to_date"
                                        v-slot="{ errors }">
                      <v-text-field
                          v-model="item.from_date"
                          label="From Date"
                          hint="Date from which period is supposed to start from"
                          prepend-icon="mdi-calendar"
                          readonly
                          required
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                      <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                      v-model="item.from_date"
                      @input="from_date_menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                  cols="12"
              >
                <v-menu
                    v-model="to_date_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider mode="passive" vid="to_date" name="To Date" rules="required"
                                        v-slot="{ errors }">
                      <v-text-field
                          v-model="item.to_date"
                          label="To Date"
                          hint="Date on which period is supposed to end"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                      <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                      v-model="item.to_date"
                      @input="to_date_menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                type="submit"
                :loading="submit_loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import axios from "axios";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import "@/validations/office_validations"

export default {
  name: "add_edit_form",
  props: ['passed_item', 'icon', 'activator_name', 'activator_icon', 'form_title', 'action', 'big_btn', 'url', 'snackbar_text', 'tooltip_text', 'parent_item', 'loading'],
  components: {ValidationProvider, ValidationObserver},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
  },
  data: () => ({
    submit_loading: false,
    dialog: false,
    item: {
      id: null,
      name: null,
      from_date: null,
      to_date: null,
    },
    from_date_menu: false,
    to_date_menu: false,
    non_field_errors: []
  }),
  methods: {
    async add_new_item() {
      this.axios_conf
      let data = {
        name: this.item.name,
        from_date: this.item.from_date,
        to_date: this.item.to_date,
        parent_period: this.parent_item.id,
        authorizer: {},
        closer: {},
        inputter: {},
      }
      await axios.post(this.url, data)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.dialog = false
            this.$refs.vform.reset()
            this.$refs.vform.resetValidation()
            this.$emit('form_status', true)
            this.$store.commit("update_snackbar", {
              text: this.snackbar_text.replace('{value}', data.name),
              status: true
            })
          })
          .catch((error) => {
            // console.log(error.response.data)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              // console.log(error.response.data.key)
              // this.non_field_errors = error.response.data.errors.non_field_errors;
              this.$refs.observer.setErrors({
                name: error.response.data.errors.name,
                from_date: error.response.data.errors.from_date,
                to_date: error.response.data.errors.to_date,
                non_field: error.response.data.errors.non_field_errors
              });
            }
          })
      this.submit_loading = false
    },
    async edit_item() {
      this.axios_conf
      let data = this.item
      data.authorizer = {}
      data.closer = {}
      data.inputter = {}
      delete data.closing_timestamp
      delete data.authorize_timestamp
      await axios.patch(this.url.replace('{id}', data.id), data)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.dialog = false
            this.$refs.vform.reset()
            this.$refs.vform.resetValidation()
            this.$emit('form_status', true)
            this.$store.commit("update_snackbar", {text: this.snackbar_text, status: true})
          })
          .catch((error) => {
            // console.log(error.response.data)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              // console.log(error.response.data.key)
              this.$refs.observer.setErrors({
                name: error.response.data.errors.name,
                from_date: error.response.data.errors.from_date,
                to_date: error.response.data.errors.to_date,
                non_field: error.response.data.errors.non_field_errors
              });
            }
          })
      this.submit_loading = false
    },
    handle_submit() {
      this.submit_loading = true
      if (this.action === 'add') {
        this.add_new_item()
      } else if (this.action === 'edit') {
        this.edit_item()
      }
    },
  },
  watch: {
    passed_item(val) {
      // console.log(val)
      this.item = JSON.parse(JSON.stringify(val))
      // console.log(this.item)
    },
    // zone(val) {
    //   console.log(val)
    // }
  },
  mounted() {
    if (this.passed_item) {
      this.item = JSON.parse(JSON.stringify(this.passed_item))
    }
  }
}
</script>

<style scoped>
</style>